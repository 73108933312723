module services {
    export module purchase {
        export class deliveryInstructionSundryItemService implements interfaces.purchase.IDeliveryInstructionSundryItemService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDetail(): ng.resource.IResourceClass<interfaces.purchase.IDeliveryInstructionSundryItem> {

                return this.$resource<interfaces.purchase.IDeliveryInstructionSundryItem>(this.ENV.DSP_URL + "DeliveryInstructionSundryItem/GetDetail", {
                    Id: '@Id',
                    pdiId: '@pdiId',
                }, {
                        get: {
                            method: 'GET',
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            getByDeliveryInstructionId(): ng.resource.IResourceClass<interfaces.purchase.IDeliveryInstructionSundryItemDisplay> {

                return this.$resource<interfaces.purchase.IDeliveryInstructionSundryItemDisplay>(this.ENV.DSP_URL + "DeliveryInstructionSundryItem/GetByDeliveryInstructionId", {
                    pdiId: '@pdiId'
                });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "DeliveryInstructionSundryItem/Save");
            }

            delete(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "DeliveryInstructionSundryItem/Delete", {
                    pdsId: '@pdsId'
                });
            }

            generateSundryItems(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "DeliveryInstructionSundryItem/GenerateSundryItems", {
                    pdiId: '@pdiId'
                });
            }
            
        }
    }
    angular.module("app").service("deliveryInstructionSundryItemService", services.purchase.deliveryInstructionSundryItemService);
}